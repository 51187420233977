import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

const ServiceCard = () => {
  const { t } = useTranslation()
  return (
    <section id="sectionAlLaCarte" className="aLaCarte">
      <div className="aLaCarte__content">
        <div className="container aLaCarte__container">
          <div className="aLaCarte__inside">
            <div className="aLaCarte__img">
              <img src="/img/home/service/to-do-list.svg" alt="" />
            </div>

            <h2 className="h1-style aLaCarte__title">
              {t('page_home_section_aLaCarte_title')}
            </h2>
            <h3 className="aLaCarte__subtitle h4-style font-bold  ">
              {t('page_home_section_aLaCarte_subtitle')}
            </h3>
            <p className="aLaCarte__descr font-medium h5-style">
              {t('page_home_section_aLaCarte_descr')}
            </p>
          </div>
          <div className="service__cards">
            <div className="service__card">
              <div className="service__card-image">
                <img
                  className="service__card-image--img"
                  src="/img/home/service/service-1.svg"
                  alt=""
                />
              </div>
              <div className="h4-style div-style service__card-content--title responsive font-bold">
                {t('page_home_graphism_title')}
              </div>
              <div className="service__card-content little">
                <div className="h4-style div-style service__card-content--title font-bold">
                  {t('page_home_graphism_title')}
                </div>
                <p className="service__card-content--text font-medium text-primary ">
                  {t('page_home_graphism_paragrah')}
                </p>
                <Link
                  to="/graphisme"
                  className="service__card-content--link text-primary"
                >
                  {t('constant_learn_more')}
                </Link>
              </div>
            </div>

            <div className="service__card">
              <div className="service__card-image">
                <img
                  className="service__card-image--img"
                  src="/img/home/service/service-4.svg"
                  alt=""
                />
              </div>
              <div className="h4-style service__card-content--title responsive font-bold">
                {t('page_home_writing_title')}
              </div>
              <div className="service__card-content little">
                <div className="h4-style service__card-content--title font-bold">
                  {t('page_home_writing_title')}
                </div>
                <p className="service__card-content--text font-medium text-primary">
                  {t('page_home_writing_paragrah')}
                </p>
                <Link to="/writing" className="service__card-content--link">
                  {t('constant_learn_more')}
                </Link>
              </div>
            </div>

            <div className="service__card">
              <div className="service__card-image">
                <img
                  className="service__card-image--img"
                  src="/img/home/service/service-3.svg"
                  alt=""
                />
              </div>
              <div className="h4-style service__card-content--title responsive font-bold">
                {t('page_home_translate_title')}
              </div>
              <div className="service__card-content little">
                <div className="h4-style service__card-content--title font-bold">
                  {t('page_home_translate_title')}
                </div>
                <p className="service__card-content--text font-medium text-primary">
                  {t('page_home_translate_paragrah')}
                </p>
                <Link to="/translate" className="service__card-content--link">
                  {t('constant_learn_more')}
                </Link>
              </div>
            </div>

            <div className="service__card">
              <div className="service__card-image">
                <img
                  className="service__card-image--img"
                  src="/img/home/service/service-2.svg"
                  alt=""
                />
              </div>
              <div className="h4-style service__card-content--title responsive font-bold">
                {t('page_home_social_title')}
              </div>
              <div className="service__card-content small">
                <div className="h4-style service__card-content--title font-bold">
                  {t('page_home_social_title')}
                </div>
                <p className="service__card-content--text font-medium text-primary">
                  {t('page_home_social_paragrah')}
                </p>
                <Link to="/social" className="service__card-content--link">
                  {t('constant_learn_more')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceCard
